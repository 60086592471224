.staff-card {
  gap: 0.3em;
  border: 1px solid #bbb;
  border-radius: 6px;
  padding: 7px 10px;
  font-size: 18px;
  font-family: Arial;
  align-items: center;
}

.staff-card-info {
  flex-grow: 1;
}

.staff-card-history-icon {
  width: 1.6em;
  height: 1.6em;
  padding: 0.5em;
  margin: -0.5em 0em;
  cursor: pointer;
}