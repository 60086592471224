.navbar .title {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

.navbar .user {
  color: white;
  cursor: pointer;
}