.font-x-large {
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
}

.font-large {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.font-medium {
  font-size: 14px;
  line-height: 20px;
}

.font-small {
  font-size: 12px;
  line-height: 18px;
}

.box {
  display: flex;
  min-width: 0;
}

.box.horizontal {
  flex-direction: row;
}

.box.vertical {
  flex-direction: column;
}

.text {
  white-space: nowrap;
}

.text.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.expand {
  flex-grow: 1;
}