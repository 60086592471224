.staff-device {
  gap: 0.5em;
  align-items: center;
}

.staff-device-icon {
  padding: 0.2em;
  height: 1.6em;
  width: 1.6em;
}

.staff-device-info {
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

.staff-device-location {
  gap: 0.5em;
}